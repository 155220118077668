angular.module('angus.controllers').controller('paygoTMSAdminCtrl', [
    '$scope', '$rootScope','Paging', 'fluentRest', 'Throttler', '$http', 'actionViews', '_', 'constantsService', 'subscriberService',
    function($scope, $rootScope, Paging, fluentRest, Throttler, $http, actionViews, _, constantsService, subscriberService) {
        'use strict';

        $scope.load = true;

        $scope.dealerSelectVisible = true;
        $scope.context = {
            selectedDealers: [],
            inactiveMonitor: false,
            unMappedAccounts: false
        };

        $scope.clearSearch = function() {
            delete $scope.searchTerm;
            setModel();
            $scope.getMonitors();
        };

        $scope.getMonitors = function(pageNumber) {
            var params = {
                searchTerm      : $scope.searchTerm,
                sortBy          : $scope.sortBy,
                ascending       : $scope.ascending,
                dealerIds       : $scope.context.selectedDealers,
                inactiveMonitor : $scope.context.inactiveMonitor, 
                unMappedAccounts: $scope.context.unMappedAccounts
            };

            if($scope.widget.instance.settings.statusIds.value &&
                $scope.widget.instance.settings.statusIds.value.length > 0)
                params.statusIds = $scope.widget.instance.settings.statusIds.value;

            var url = fluentRest
                .api()
                .subscribers($scope.selectedSubscriber.id)
                .containers()
                .monitors()
                .forDealers()
                .toString();

            return $scope.paging.getPage(url, pageNumber, params)

                .then(function(monitors) {
                    $scope.monitors = monitors.items;
                    $scope.showInactiveMonitorColumn = $scope.context.inactiveMonitor;
                })
                .catch(function(){
                    $scope.monitors = [];
                });
        };

        function setModel(){
            $scope.widget.setDefaultActionModel({
                subscriber      : $scope.selectedSubscriber,
                searchTerm      : $scope.searchTerm,
                dealerIds       : $scope.context.selectedDealers,
                inactiveMonitor : $scope.context.inactiveMonitor,
                unMappedAccounts: $scope.context.unMappedAccounts
            });
        }

        $scope.searchTermChange = function(term){
            $scope.searchThrottler
                .throttle()
                .then(function(takeAction) {
                    if(takeAction){
                        $scope.searchTerm = term;
                        setModel();
                    }
                });
        };

        $scope.subscriberChange = function(subscriber) {
            $scope.selectedSubscriber = subscriber; 
            $rootScope.selectedSubscriber = subscriber;
            $scope.getDealers();
            setModel();
            checkIsNonIntegrated();
        };

        $scope.sort = function(sortBy) {
            if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            $scope.getMonitors();
        };
        $scope.getDealers = function () {
            var subscriberId = $scope.isSysAdmin && $scope.selectedSubscriber ? $scope.selectedSubscriber.id : $rootScope.user.subscriberId;
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .dealers($scope.isSysAdmin ? undefined : $rootScope.user.hierarchy.dealer.nodeId)
                .get()
                .then(function(dealers) {
                    $scope.dealerSelectVisible = !(!$scope.isSysAdmin && (!dealers || dealers.length <= 1));

                    $scope.dealers = dealers;
                    $scope.context.selectedDealers = _.map($scope.dealers, 'id')

                });
        };

        function getSubscriberFromSettings(){
            return _.find($scope.subscribers, function(subscriber){
                return subscriber.id == $scope.widget.instance.settings.subscriberId.value;
            });
        }

        $scope.monitorSelected = function(monitor) {
            $scope.widget.openDetailView(actionViews.getActionView($scope.widget.code), {
                monitor: monitor
            });
            setModel();
        };
 
        function checkIsNonIntegrated() {  
            var currentUserSubscriberId = $scope.isSysAdmin ? 
                        ($scope.selectedSubscriber ? $scope.selectedSubscriber.id : $rootScope.user.subscriberId)
                        : $rootScope.user.subscriberId;

            return $http({
                method: 'GET',
                url: ('api/subscribers/{0}/tanks/integrated').format(currentUserSubscriberId),
                data: {}
            })
                .then(function (status) {
                    $scope.isNonIntegrated = status.data;
                }).catch(function (err) {
                    $scope.isNonIntegrated = false;
                }); 
          }
        $rootScope.$on('monitorUpdate', function(event, data) {
            if (data) {
                $scope.getMonitors();
            }
        });
        function init() {

            $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';
            $scope.paging = new Paging($scope.pageSize || 10);

            $scope.sortBy = 'serialNumber';
            $scope.ascending = true;
            $scope.context.inactiveMonitor = false;
            $scope.searchThrottler = new Throttler(250);

            var promise = subscriberService.getAmsSubscribers()
                .then(function(subscribers){
                    $scope.subscribers = _.filter(subscribers, function(subscriber){
                        return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                    });
                    $scope.selectedSubscriber = $scope.widget.instance.settings.subscriberId && $scope.widget.instance.settings.subscriberId.value  ?  getSubscriberFromSettings() : $scope.subscribers[0];
                    $scope.widget.setDefaultActionModel({
                        subscriber    : $scope.selectedSubscriber,
                    });
                    checkIsNonIntegrated();
                    return $scope.getDealers($scope.selectedSubscriber);
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.submit = function() {
            setModel();
            $scope.getMonitors();
        }

    }

]);