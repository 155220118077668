angular.module('angus.controllers').controller('tmsDailyLevelsCtrl', [
    '$scope', '$rootScope','Paging', 'fluentRest', 'Throttler', 'actionViews', 'hierarchyService', '_',
    function($scope, $rootScope, Paging, fluentRest, Throttler, actionViews, hierarchyService, _) {
        'use strict';

        var dealerId =  $rootScope.user.hierarchy.dealer.nodeId;
        if ($scope.widget.instance.settings) {
            dealerId = $scope.widget.instance.settings.hierarchyNodeId;
        }

        $scope.load = true;

        $scope.clearSearch = function() {
            delete $scope.searchTerm;
            setModel();
            $scope.getMonitors();
        };
        $scope.context = {
            selectedDealers: []
        };

        $scope.getMonitors = function(pageNumber) {
            var params = {
                dealerId   : $scope.context.selectedDealers,
                searchTerm : $scope.searchTerm,
                sortBy     : $scope.sortBy,
                ascending  : $scope.ascending,
            };

            var url = fluentRest.api().subscribers($rootScope.user.subscriberId).containers().monitors().toString();

            return $scope.paging.getPage(url, pageNumber, params)
            .then(function(monitors) {
                $scope.monitors = monitors.items;
            })
            .catch(function(){
                $scope.monitors = [];
            });
        };

        function setModel(){
            $scope.widget.setDefaultActionModel({
                searchTerm  : $scope.searchTerm
            });
        }

        $scope.searchTermChange = function(term){
            $scope.searchThrottler
            .throttle()
            .then(function(takeAction) {
                if(takeAction){
                    $scope.searchTerm = term;
                    setModel();
                    $scope.getMonitors();
                }
            });
        };

        $scope.sort = function(sortBy) {
            if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            $scope.getMonitors();
        };


        $scope.monitorSelected = function(monitor) {
            $scope.widget.openDetailView(actionViews.getActionView($scope.widget.code), {
                monitor: monitor
            });
        };

        $scope.$on('widgetSettingsChanged', function() {
            dealerId = $scope.widget.instance.settings.hierarchyNodeId;
        });

        $scope.getDealers = function () {
            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .dealers()
                .get()
                .then(function(dealers) {  
                    $scope.dealers = dealers; 
                    $scope.context.selectedDealers = _.map($scope.dealers, 'id');
                    $scope.getMonitors()
                });
        };
        $rootScope.$on('monitorUpdate', function(event, data) {
            if (data) {
                $scope.getMonitors();
            }
        });
        function init() {

            $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';

            $scope.paging = new Paging($scope.pageSize || 10);
            $scope.sortBy = 'serialNumber';
            $scope.ascending = true;
            $scope.searchThrottler = new Throttler(250);
            return $scope.widget.promises.monitor($scope.getDealers());
        }

        $scope.widget.setDefaultInitialization(init);


        $scope.$on('widgetSettingsChanged', function() {
            $scope.getMonitors();
        });
    }
]);
