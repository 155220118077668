angular.module('angus.controllers')
    .controller('tmsDailyLevelsContainerCtrl', ['$rootScope', '$scope', '$http', '$window', 'modalService', '$sce', 'actionViewTemplates', 'alertsService', '_', 'windowsService',
        function ($rootScope, $scope, $http, $window, modalService, $sce, actionViewTemplates, alertsService, _, windowsService) {
            'use strict';

            windowsService.setCallingScopeCallBack($scope.windowId, function(newCallingScope) {
                const newModel = newCallingScope.model;

                if (newModel.isAdmin) {
                    $scope.isAdmin = true;
                }

                if (newModel.monitor) {
                    $scope.openMonitor(newModel.monitor);
                }
                else {
                    $scope.activeItem = $scope.monitorsItem;
                }

            })

            $scope.alerts = new alertsService();

            $scope.monitorsItem = {
                user: $rootScope.user.role,
                name: 'Monitors',
                icon: 'map',
                templateUrl: actionViewTemplates.paygoTMSAdminMonitors,
                active: true,
                isAdmin: ($rootScope.user.role === 'sysadmin' || $rootScope.user.role === 'admin')
            };

            $scope.trustSrc = function (src) {
                return $sce.trustAsResourceUrl(src);
            }

            function checkIsNonIntegrated() {
                return $http({
                    method: 'GET',
                    url: ('api/subscribers/{0}/tanks/integrated').format($rootScope.user.subscriberId),
                    data: {}
                })
                    .then(function (status) {
                        $scope.isNonIntegrated = status.data;
                    }).catch(function (err) {
                        $scope.isNonIntegrated = false;
                    });

            }

            function checkGroupAccess() {
                $http.get(('api/subscribers/{0}/securityGroups').format($rootScope.user.subscriberId))
                .then(function(securityGroups) { 
                    var group =  _.find(securityGroups.data, function(x) {
                    return x.id == $rootScope.user.securityGroupId;
                    });  
                    if (group.id === $rootScope.user.securityGroupId) {
                        $scope.isAdmin = group.name.startsWith('Admin');
                    } 
                });
            } 
            
            $scope.viewTankPhoto = function() {  
                var monitor = $scope.monitor || $scope.activeItem.monitor;
                var scope = {
                    photo: monitor.photoUri,
                    tank: monitor.tankDescription
                }; 

                return modalService
                .openTopBarModal(actionViewTemplates.paygoCustRecViewPhoto, scope)
                .spread(function(photo) {   
                });
            }

            $rootScope.$on("paygo_unmapping_saved", function (evt, data) {
                var data = JSON.parse(data);
                if (data && data.serialNumber && $scope.items.length > 1) {
                  var serialNumber = data.serialNumber;
                  var item = _.find($scope.items, function(item) {
                    return item.monitor && item.monitor.serialNumber == serialNumber;
                  }); 
                  var index = _.findIndex($scope.items, function(item) {
                      return item.monitor && item.monitor.serialNumber == serialNumber;
                  }) || 1;
                  _.remove($scope.items, item);
                  if (index <= $scope.items.length - 1) { 
                    $scope.activeItem = $scope.items[index];
                  }
                  else { 
                    $scope.activeItem = $scope.items[index - 1];
                  }
                }
                if ($scope.items.length == 0) {
                  $scope.items = [$scope.monitorsItem];  
                  $scope.activeItem = $scope.items[0];
                  $rootScope.$broadcast("monitorUpdate", data); 
                }
            });

            function init() {
                $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';
                $scope.lastSearchTerm = null;
                $scope.pageSize = 20;
                $scope.items = [$scope.monitorsItem];

                var activeItem = localStorage.getItem('activeItem');
                var scope = activeItem ? JSON.parse(activeItem) : null;
                if (scope && !scope.hideSideNav) $scope.sideNavTemplate = actionViewTemplates.sideNav;
                if (scope && scope.model && scope.model.monitor) $scope.monitor = scope.model.monitor; 

                if ($scope.monitor)
                    $scope.openMonitor($scope.monitor);
                else {
                    $scope.activeItem = $scope.monitorsItem;
                }

                $scope.isTMSDailyLevels = true;
                checkIsNonIntegrated();
                checkGroupAccess();
            }

            $scope.switchActiveItem = function (item) {
                if (item.active) {
                    $scope.activeItem = item;
                }
            };

            $scope.removeMonitor = function (item) {
                var index = _($scope.items).findIndex(item);
                _.remove($scope.items, item);
                if (index <= $scope.items.length - 1) $scope.activeItem = $scope.items[index];
                else $scope.activeItem = $scope.items[index - 1];
            };

            $scope.downloadTutorial = function(){       
                getStorageFile('GREMLIN-Un-map_or_Edit_Mapping_Instructions.pdf');
            } 
            
            function getStorageFile(filename) {
                var url = ('api/storage'); 
                var data = { filename };
                var promise = $http.post(url, data)
                .success(function(result) {
                    if (result) { 
                    $window.open(result, '_blank');
                    }
                    else {
                    $window.alert('File not found!');
                    }
                })
                .error(function(data, status) {  
                    $window.alert('File not found!');
                }); 
            } 

            function openItem(serialNumber) {
                return _.find($scope.items, function (i) {
                    return i.monitor && i.monitor.serialNumber == serialNumber;
                });
            }

            function newItem(monitor) {
                var item = {
                    icon: 'square',
                    templateUrl: actionViewTemplates.paygoTMSAdminMonitor,
                    active: true,
                    monitor: monitor,
                    name: monitor.customer.name
                };
                $scope.items.push(item);

                return item;
            }
            function newMapped(monitor) {
                var item = {
                    icon: 'square',
                    templateUrl: actionViewTemplates.paygoTMSAdminMonitor,
                    active: true,
                    monitor: monitor,
                    name: monitor.customer.name,
                    activeSection: "geometry",

                };
                $scope.items.push(item);

                return item;
            }

            function isMonitorOpen(serialNumber) {
                return !!openItem(serialNumber);
            }

            $scope.openMonitor = function (monitor) {
                if ($scope.monitorsItem.selectedSubscriber) {
                    $rootScope.impersonateAbosKey = $scope.monitorsItem.selectedSubscriber.abosKey;
                }
                $scope.currentMonitor = monitor;
                localStorage.setItem('activeItem', JSON.stringify(monitor));
                $scope.lastSearchTerm = $scope.monitorsItem.searchTerm;
                $scope.activeItem = isMonitorOpen(monitor.serialNumber) ? openItem(monitor.serialNumber) : newItem(monitor); 
            };
            $scope.openNewMappedMonitor = function (monitor) {
                $scope.currentMonitor = monitor;
                localStorage.setItem('activeItem', JSON.stringify(monitor));
                $scope.lastSearchTerm = $scope.monitorsItem.searchTerm;
                if(!_.isEmpty(monitor)){
                    var adjustMonitor = monitor;
                    adjustMonitor.activeSection = "geometry";
                    for(let i = 0; i < $scope.items.length; i++){
                        let item = $scope.items[i];
                        if(item.icon === "square" && item.monitor.serialNumber === monitor.serialNumber ){
                            $scope.items.splice(i,++i);
                        }
                    }

                    $scope.activeItem = newMapped(monitor);
                }

            };

            init();


        }]);
