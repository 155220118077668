angular.module('angus.controllers').controller('riderController', [
    '$scope', '$rootScope', 'moment',  '_', 'riderService', 'salesService', 'subscriberService', 'constantsService', 'orderService', 'inventoryManagementService' ,
    function ($scope, $rootScope,  moment, _, riderService, salesService, subscriberService, constantsService, orderService, inventoryManagementService ) {
        'use strict';
      
        $scope.isLoading = false;  
        $scope.alerts = []; 
        $scope.riderAlerts = [];
        $scope.isCreateMode = false;   
        $scope.isEditMode = false;
        $scope.areSubscriberDropdownsLoading = false;
        $scope.areRiderDropdownsLoading = false;
        $scope.areSalesRepDropdownsLoading = false;
        $scope.areFinancingTypesDropdownsLoading = false;
        $scope.areItemsLoading = false;  
        $scope.areCarriersLoading = false;   
        $scope.selectedSubscriber = null;
        $scope.selectedSubscriberKey = null;
        $scope.rowStatus = {
            active: 1,
            delete: 3
        }
        $scope.riderLineForm = {}; 
        $scope.salesRepresentativeDropdown = []; 
        $scope.riderDropdown = [];
        $scope.financeTypeDropdown = [];
        $scope.subscribers = []; 
        $scope.includeDeleted = 0;
        $scope.riderOrderVM = [];
        $scope.totalRiderOrderMonitorQuantity = 0;
        $scope.savedRiderLines = [];
        $scope.defaultRiderModel = {};

        function init() {
            $scope.newRiderModel();
            Promise.all([
                getAmsSubscribers(),
                getSalesReps(),
                getFinancingTypes()
            ])
            .then(function(results) {
            });
        } 
 
        //Public Methods ******************************************************************** 
        $scope.newRiderModel = function() { 
            $scope.riderModel = {  
                riderKey: null,
                riderID: null, 
                subscriberKey: null,
                salesRepresentativeKey: null,
                financingTypeKey: 1,
                riderCreationDate: new Date(),
                riderAcceptedDate: null,
                //If the ADEPT Client flag is true, this should default to 0.  Otherwise leave blank. 
                riderMonthlyFee: ($scope.subscriber && $scope.subscriber.isPaygo) ? 0 : null,
                salesTaxRate: 0,
                feeDelayMonths: 3,
                perUnitShippingFee: 0,
                otherFees: 0,
                otherPerUnitFees: 0,
                otherPerUnitFeesQuantity: 0,  
                dynamicReserveFlag: $scope.subscriber ? $scope.subscriber.isPaygo : 0,
                dynamicReserveStartDate: $scope.subscriber ? inventoryManagementService.toDate($scope.subscriber.monitorCreditStartDate, 'MM/DD/YYYY') : null,
                dynamicReserveEndDate: $scope.subscriber ? inventoryManagementService.toDate($scope.subscriber.monitorCreditEndDate, 'MM/DD/YYYY') : null,
                financingMonths: 0,
                oneTimePrice: 0,
                monthlyPrice: 0,
                riderNotes: '',
                rowStatusKey: $scope.rowStatus.active,
                riderLine: []
            }  
            $scope.riderOrderVM = [];
        } 

        $scope.newRiderLineModel = function() { 
            $scope.riderLineModel = {
                riderLineKey: null, 
                riderKey: $scope.riderModel.riderKey, 
                monitorTypeKey: null, 
                monitorTypeName: null, 
                monitorTypeDescription: null, 
                monitorTypeCalculation: null, 
                simCardCarrierKey: null,
                riderUnitPhysicalQuantity: null, 
                riderUnitBillingQuantity: null, 
                riderLineMonthlyUnitFee: null,
                riderLineShipmentUnitFee: null,
                riderLineUnitPrice: null, 
                orderedQuantity: null, 
                riderLineNotes: null, 
                rowStatusKey: $scope.rowStatus.active
            }
            $scope.riderModel.riderLine.push($scope.riderLineModel);
        } 

        $scope.clickAddRider = function() {
            $scope.riderDropdown = [{ riderKey: null,  riderID: '<--- Create New --->' }];   
            $scope.newRiderModel();  
            $scope.isCreateMode = true; 
            $scope.isEditMode = true;
        } 
 
        $scope.submitHandler = function(form, rowStatusKey) {
            $scope.riderSubmitLoading = true;  
            if ($scope.selectedSubscriberKey) { 
                $scope.riderModel.riderMonthlyFee = safeSpecialCharacterParse('riderMonthlyFee', $scope.riderModel.riderMonthlyFee);
                $scope.riderModel.feeDelayMonths = safeSpecialCharacterParse('feeDelayMonths', $scope.riderModel.feeDelayMonths);
                $scope.riderModel.perUnitShippingFee = safeSpecialCharacterParse('perUnitShippingFee', $scope.riderModel.perUnitShippingFee); 
                $scope.riderModel.otherFees = safeSpecialCharacterParse('otherFees', $scope.riderModel.otherFees);
                $scope.riderModel.otherPerUnitFees = safeSpecialCharacterParse('otherPerUnitFees', $scope.riderModel.otherPerUnitFees); 
                $scope.riderModel.subscriberKey = $scope.selectedSubscriberKey; 
                $scope.riderModel.dynamicReserveFlag = $scope.riderModel.dynamicReserveFlag ? 
                                                        ($scope.riderModel.dynamicReserveFlag ? 1 : 0) : 0;
                $scope.riderModel.updateUser = $rootScope.user.username,
                $scope.riderModel.rowStatusKey = rowStatusKey;  
                
                for (let index = 0; index < $scope.riderModel.riderLine.length; index++) {
                    const line = $scope.riderModel.riderLine[index];
                    line.riderLineUnitPrice = safeSpecialCharacterParse('riderLineUnitPrice', line.riderLineUnitPrice);  
                }

                try {
                    riderService.postRider($scope.riderModel)
                    .then(function(result) {
                        var riderKey = result[0].RiderKey;
                        if (riderKey > 0) {  
                            if (form) {
                                form.$setPristine();  
                            } 
                            if (rowStatusKey == $scope.rowStatus.active) {
                                $scope.alerts.push({ type: 'success', msg: 'Successfully Saved' });  
                         
                                getRider(riderKey); 
                                $scope.riderOrderVM.forEach(vm => {    
                                    updateQuantity(vm);
                                });     
                            }
                            else { 
                                $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                                //clear existing data
                                $scope.newRiderModel();
                            }
                            getRiderHeader();
                        }
                        else { 
                            $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                        }
                    }); 
                } catch (error) { 
                    $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
                }
                $scope.cancelHandler(form);
                $scope.riderSubmitLoading = false;
                $scope.isCreateMode = false; 
                $scope.isEditMode = true; 
            }
        }  
        
        $scope.quanityEnteredHandler = function(riderLine) { 
            if (!riderLine.riderUnitBillingQuantity) {
                riderLine.riderUnitBillingQuantity = riderLine.riderUnitPhysicalQuantity;  
            }
            if (orderExists(riderLine)) { 
                $scope.riderAlerts = [];
                $scope.riderAlerts.push({
                    type: 'danger', 
                    msg: `You have entered ${riderLine.riderUnitPhysicalQuantity}.  Please delete orders before updating rider quanity.`
                }); 

                var foundRiderLine = _.find($scope.savedRiderLines, function(rl){
                    return rl.riderLineKey == riderLine.riderLineKey;
                }); 
                if (foundRiderLine) {
                    riderLine.riderUnitPhysicalQuantity = foundRiderLine.riderUnitPhysicalQuantity;
                }
            }
        };

        $scope.quanityHandler = function(riderLine) { 
            //check if value exist
            if (!riderLine.riderUnitPhysicalQuantity) return;
            //check if new record
            if (!riderLine.riderLineKey) return;
        }

        $scope.cancelHandler = function(form) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
            
            getRiderHeader(); 
            //revert the change
            $scope.riderModel = _.cloneDeep($scope.defaultRiderModel);   
            $scope.alerts = [];
            $scope.isCreateMode = false;
        }   
        
        $scope.riderDeletedHandler = function() {    
            getRiderHeader();     
        } 

        $scope.riderDropDownHandler = function(riderKey) {    
            $scope.riderModel.riderID = null;   
            $scope.riderOrderVM = []; 
            getRider(riderKey);    
            $scope.isEditMode = true; 
        } 
        
        $scope.subscriberChange = function() { 
            $scope.isCreateMode = false;
            $scope.subscriber = _.find($scope.subscribers, function(subscriber){
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            $scope.newRiderModel();
            getRiderHeader();  
        };

        $scope.addNewRiderLineRow = function() {
            $scope.newRiderLineModel();    
            getMonitorTypes();
            getSimCardCarriers();  
        }   
       
        $scope.addNewRiderOrderForm = function() {
            var orderModel = {  
                riderKey                : $scope.riderModel.riderKey,
                riderOrderKey           : null,
                riderOrderDate          : new Date(),
                riderOrderShippingNotes : '',
                updateUser              : '',
                rowStatusKey            : $scope.rowStatus.active,
                riderOrderLine          : []
            }    
            $scope.riderModel.riderLine.forEach(rl => {
                newOrderLineModel(orderModel, rl);
            }); 
            var vm = {
                index: getVMCount(), 
                alerts: $scope.alerts,
                includeDeleted: $scope.includeDeleted,
                username: $rootScope.user.username, 
                riderOrders: orderModel,
                rider: $scope.riderModel,  
                deleteVM: deleteVM,
                calculateTotalQuantity: calculateTotalQuantity,
                submitHandler: $scope.submitHandler,
                getRider: getRider
            }
            $scope.riderOrderVM.push(vm); 
        }

        $scope.clickDeleteRider = function(form) {  
            if(confirm("Are you sure to delete the rider?")) {
                $scope.submitHandler(form, $scope.rowStatus.delete); 
            }
        }

        $scope.clickDeleteLine = function(form, index) {   
            var lineItem = $scope.riderModel.riderLine[index];
            if (!lineItem.riderLineKey) {
                //if this record does not exist in DB then remove it.
                $scope.riderModel.riderLine.splice(index, 1); 
            }
            else { 
                if (orderExists(lineItem)) {
                    var warningMessage = 'Please remove the orders before delete the rider quantities!';
                    alert(warningMessage);
                } 
                else {
                    var deleteMessage = "Are you sure to delete this line item?"; 
                    if(confirm(deleteMessage)) {  
                        $scope.riderModel.riderLine[index].rowStatusKey = $scope.rowStatus.delete;  
                        form.$setDirty();
                    } 
                }
            }  
        }

        $scope.closeAlert = function (index, type) {
            if (type == 'alerts') {
                $scope.alerts.splice(index, 1);
            }
            else {
                $scope.riderAlerts.splice(index, 1);
            }
        } 

        $scope.allowNewOrder = function() { 
           var newOrders = _.filter($scope.riderOrderVM, function(vm) {
                return vm.riderOrders.riderOrderKey == null; 
            }) 
            return newOrders.length == 0;
        }
 
        //Private Methods ******************************************************************** 

        var newOrderLineModel = function(orderModel, riderLine) {  
            var orderLine = {
                riderLineKey                    : riderLine.riderLineKey ? riderLine.riderLineKey : null,
                riderOrderItem                  : riderLine.monitorTypeName ? riderLine.monitorTypeName : null,
                riderOrderCarrier               : riderLine.simCardCarrierKey ? riderLine.simCardCarrierKey : null, 
                riderOrderLineQuantity          : riderLine.riderOrderLineQuantity ? riderLine.riderOrderLineQuantity : null,
                shipmentQuantity                : riderLine.shipmentQuantity ? riderLine.shipmentQuantity : null,
                riderOrderLineNotes             : riderLine.riderOrderLineNotes ? riderLine.riderOrderLineNotes : '',
                rowStatusKey                    : riderLine.rowStatusKey ? riderLine.rowStatusKey : 1
            }  
            orderModel.riderOrderLine.push(orderLine); 
        } 

        var getVMCount = function() {
            var vmCount = $scope.riderOrderVM.length;
            return vmCount === 0 ? vmCount : vmCount - 0;
        }

        var deleteVM = function(index) { 
            $scope.riderOrderVM.splice(index, 1); 
        }

        var populateRiderOrders = function(riderKey, order) {  
            var riderLines = order.riderLine;  

            return orderService.getOrderHeader(riderKey, null, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {    
                var headers = []; 
                headers = _.filter(result, function(header) {
                    return header.rowStatusKey == $scope.rowStatus.active;
                }) 
                headers.forEach((order, index) => {
                    getRiderOrder(index, order.riderOrderKey, riderLines);
                });
            });
        }

        var getRiderOrder = function(index, riderOrderKey, riderLines) {
            return orderService.getOrder(null, riderOrderKey, $scope.includeDeleted)
            .then(function(result) {
                var vm = {
                    index: getVMCount(),
                    alerts: $scope.alerts, 
                    includeDeleted: $scope.includeDeleted,
                    username: $rootScope.user.username, 
                    riderOrders: mapRiderOrder(result, riderLines),
                    rider: $scope.riderModel,  
                    deleteVM: deleteVM,
                    calculateTotalQuantity: calculateTotalQuantity,
                    submitHandler: $scope.submitHandler,
                    getRider: getRider
                }
                $scope.riderOrderVM.push(vm);  
                updateQuantity(vm);
            });
        }

        var calculateTotalQuantity = function(riderLineKey, riderOrderLineKey, riderOrderLineQuantity) { 
            var total = 0;
            $scope.riderOrderVM.forEach(vm => {
                getTotal(vm);   
            });   
            return total;

            function getTotal(vm) {
                //1. find riderOrderLine item 
                var found = _.find(vm.riderOrders.riderOrderLine, function (orderLine) {
                    return orderLine.riderLineKey == riderLineKey;
                });
                //2. if current line item was edited
                if (found) {
                    if (found.riderOrderLineKey == riderOrderLineKey) {
                        total += riderOrderLineQuantity;
                    }
                    else {
                        total += found.riderOrderLineQuantity; 
                    }
                } 
            }
        }

        var updateQuantity = function(vm) {
            $scope.riderModel.riderLine.forEach(line => {  
                var found = _.find(vm.riderOrders.riderOrderLine, function (orderLine) {
                    return orderLine.riderLineKey == line.riderLineKey;
                });   
                if (found) {  
                    line.orderedQuantity += found.riderOrderLineQuantity;
                }   
            });   
        }

        var orderExists = function(lineItem) {
            //check if rider order exists for current selection 
            var orderExists = null;
            $scope.riderOrderVM.forEach(vm => {
                orderExists = _.filter(vm.riderOrders.riderOrderLine, function (line) {
                    return line.riderLineKey == lineItem.riderLineKey;
                });
            });
            return orderExists;
        }
 
        var mapRiderOrder = function(riderOrder, riderLines) {
            var orderModel = {
                riderKey: riderOrder.riderKey, 
                riderOrderKey: riderOrder.riderOrderKey,
                riderOrderDate: inventoryManagementService.toDate(riderOrder.riderOrderDate), 
                riderOrderShippingNotes: riderOrder.riderOrderShippingNotes, 
                rowStatusKey: riderOrder.rowStatusKey, 
                riderOrderLine: mapOrderLines(riderOrder.riderOrderLine, riderLines)
            }  
            return orderModel;
        }

        var mapOrderLines = function(riderLine, riderLines) {
            var newOrderLines = _.map(riderLine, function(rol,index){
                rol.riderOrderItem = riderLines[index] ? riderLines[index].monitorTypeName : ''; 
                rol.riderOrderCarrier = riderLines[index] ? riderLines[index].simCardCarrierKey : '';
                return rol;
            }); 

            return newOrderLines;
        }

        var safeSpecialCharacterParse = function(name, value) {
            var isString = (typeof value) == "string";
            return isString ? Number(value.replace('$', '')) : value;
        }

        var getMonitorTypes = function() {   
            $scope.areItemsLoading = true;
            inventoryManagementService.getMonitorTypes($scope.subscriber.id) 
            .then(function(result) {
                $scope.monitorTypes = result;    
                $scope.areItemsLoading = false; 
            }); 
        }  
        
        var getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) {   
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        } 

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            return subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        }

        var getRiderHeader = function() {    
            $scope.areRiderDropdownsLoading = true;
            return riderService.getRiderHeader(null, null, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {  
                var headers = [];
                if ($scope.includeDeleted) {
                    headers = result;
                }
                else {
                    headers = _.filter(result, function(header) {
                        return header.rowStatusKey == $scope.rowStatus.active;
                    }) 
                }
                headers = _.map(headers, function(header){
                    header.riderID = `${header.riderID} (${inventoryManagementService.toDate(header.riderCreationDate, 'MM/DD/YYYY')})`; 
                    return header;
                });

                $scope.riderDropdown = headers;
                $scope.areRiderDropdownsLoading = false;
            }); 
        }  

        var getRider = function(riderKey) {    
            $scope.isLoading = true; 
            return riderService.getRider($scope.riderModel.riderID, riderKey, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {
                getMonitorTypes();
                getSimCardCarriers(); 
                populateRider(result);
                $scope.riderOrderVM = [];
                populateRiderOrders(riderKey, result);
                $scope.isLoading = false; 
            }); 
        }   

        var populateRider = function(data) {
            if (data.riderHeader) {
                $scope.riderModel = { 
                    riderID: data.riderHeader.riderID, 
                    riderKey: data.riderHeader.riderKey, 
                    subscriberKey: data.riderHeader.subscriberKey,
                    salesRepresentativeKey: data.riderHeader.salesRepresentativeKey,
                    financingTypeKey: data.riderHeader.financingTypeKey,
                    riderCreationDate: inventoryManagementService.toDate(data.riderHeader.riderCreationDate),
                    riderAcceptedDate: inventoryManagementService.toDate(data.riderHeader.riderAcceptedDate),
                    riderMonthlyFee: data.riderHeader.riderMonthlyFee,
                    salesTaxRate: data.riderHeader.salesTaxRate,
                    feeDelayMonths: data.riderHeader.feeDelayMonths,
                    perUnitShippingFee: data.riderHeader.perUnitShippingFee,
                    otherFees: data.riderHeader.otherFees,
                    otherPerUnitFees: data.riderHeader.otherPerUnitFees,
                    otherPerUnitFeesQuantity: data.riderHeader.otherPerUnitFeesQuantity,  
                    dynamicReserveFlag: data.riderHeader.dynamicReserveFlag || 0,
                    dynamicReserveStartDate: inventoryManagementService.toDate(data.riderHeader.dynamicReserveStartDate, 'MM/DD/YYYY'),
                    dynamicReserveEndDate: inventoryManagementService.toDate(data.riderHeader.dynamicReserveEndDate, 'MM/DD/YYYY'),
                    financeDuration: data.riderHeader.financeDuration,
                    financeFlatRate: data.riderHeader.financeFlatRate,
                    financeAmount: data.riderHeader.financeAmount,
                    riderNotes: data.riderHeader.riderNotes,
                    rowStatusKey: data.riderHeader.rowStatusKey, 
                    riderLine: getLines(data.riderLine)
                }  
                $scope.defaultRiderModel = _.cloneDeep($scope.riderModel);
            }  
            else {
                $scope.newRiderModel(); 
            }
        } 
 
        var getLines = function(riderLines) {
            $scope.savedRiderLines = _.cloneDeep(riderLines);
            var lines =  _.filter(riderLines, function(line) {
                    return line.rowStatusKey == $scope.rowStatus.active;
                });
            return lines; 
        } 

        var getSalesReps = function() { 
            $scope.areSalesRepDropdownsLoading = true;
            return salesService.getSalesRepresentatives()
            .then(function(result) {  
                $scope.salesRepresentativeDropdown = result; 
                $scope.areSalesRepDropdownsLoading = false;
            }); 
        } 

        var getFinancingTypes = function() { 
            $scope.areFinancingTypesDropdownsLoading = true;
            return inventoryManagementService.getFinancingTypes()
            .then(function(result) {
                $scope.financeTypeDropdown = result; 
                $scope.areFinancingTypesDropdownsLoading = false;
            }); 
        } 

        init();
 
    }
]);


