angular.module('angus.controllers').controller('pricePlanProfAnalysisContainerCtrl', [
    '$scope', '$rootScope', '$q', '_', 'drillService', 'moment', 'promiseMonitor', '$http', 'fluentRest', 'gridService2', 'gridState', 'nodeType',
    function($scope, $rootScope, $q, _,  drillService, moment, PromiseMonitor, $http, fluentRest, gridService2, gridState, nodeType) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.filter = {
            productIds           : $scope.settings.productIds.value,
            pricePlanIds         : [],
            divisionId 		     : $scope.settings.hierarchyNodeId,
            useBrite 			 : $scope.settings.useBrite.value,
        };

        $scope.getCompanyProfilePricePlans = function(){
            $scope.grid.setColDefs(getColDefs());
            var rowPromise = $http({
                method: 'POST',
                url: 'api/subscribers/{0}/pricingPlans/counts'.format($rootScope.user.subscriberId),
                data: $scope.filter
            })
            .then(function(result){
                console.log(result.data)
                result.data = _.map(result.data, function(count){
                    count.effectiveDate = count.effectiveDate ? moment(count.effectiveDate).utc().format('MM/DD/YYYY') : null;
                    count.expirationDate = count.expirationDate ? moment(count.expirationDate).utc().format('MM/DD/YYYY') : null;
                    return count;
                });
                return result.data;
            });
            var promise = $scope.grid.setRows(rowPromise);
            $scope.monitor = new PromiseMonitor(promise);

            return promise;
        }
 
        function getColClicks(){
            return{
                bosCustomerId : {
                    onClick : function(row){
                        drillService.openAccountInfo(row);
                    }
                }
            };
        }

        function getColDefs(){

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];


            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName'));
            colDefs.push(gridService2.colDef.createText('accountNumber', 'Account Number', 'accountNumber', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createNumber('activeContracts', 'Active Contracts', 'activeContracts', {aggFunc: 'sum'}));
            colDefs.push(gridService2.colDef.createNumber('tanksCovered', 'Number Of Tanks Covered', 'tanksCovered', { aggFunc : 'sum'}));
            colDefs.push(gridService2.colDef.createBoolean('preBuyFlag', 'PreBuy', 'preBuyFlag'));
            colDefs.push(gridService2.colDef.createText('product', 'Product', 'product'));
            colDefs.push(gridService2.colDef.createDate('effectiveDate', 'Effective Date', 'effectiveDate'));
            colDefs.push(gridService2.colDef.createDate('expirationDate', 'Expiration Date', 'expirationDate'));
            colDefs.push(gridService2.colDef.createText('sourcePricePlanTypeName', 'Price Plan Description', 'sourcePricePlanTypeName'));
            colDefs.push(gridService2.colDef.createNumber('remainingUnits', 'Units', 'remainingUnits', { aggFunc : 'sum'}));
            colDefs.push(gridService2.colDef.createText('pricePlan.name', 'Price Plan Type', 'pricePlan.name', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createNumber('units', 'Units', 'units', { aggFunc : 'sum'}));
            colDefs.push(gridService2.colDef.createNumber('remainingUnits', 'Remaining Units', 'remainingUnits', { aggFunc : 'sum'}));
            return colDefs;
        }

        var nodeColDefs;
        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs){
                nodeColDefs = colDefs;
                var gridParams = {
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Company Profile - Price Plans' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.getCompanyProfilePricePlans();
            });
    }
]);
