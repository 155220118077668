angular
    .module('angus.controllers')
    .controller('budgetRunRateContainerCtrl',
        ['$scope', '$rootScope', '$q', 'fluentRest', 'moment', 'fiscalYearService', 'constantsService', '_', 'promiseMonitor', 'gridService2', 'gridState', 'nodeType', 'restrictedAccessService', 'dateCalculator', BudgetRunRateController]);

function BudgetRunRateController($scope, $rootScope, $q, fluentRest, moment, fiscalYearService, constantsService, _, PromiseMonitor, gridService2, gridState, nodeType, restrictedAccessService, dateCalculator) {
    'use strict';

    var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
    var fiscalYearOfToday = null;


    function initFilter() {
        return {
            showEntireFiscalYear: _.get($scope, ['settings', 'periodIndicatorKey', 'value'], false),
            fiscalYear: $scope.model ? $scope.model.year : moment().year(),
            month: $scope.model ? $scope.model.month : moment().month(),
            workingDayOffset: _.get($scope, ['settings', 'workingDaySubtraction', 'value'], false) || 0,
            divisionId: $scope.settings.hierarchyNodeId || $rootScope.user.hierarchy.division.nodeId,
            productCategoryIds: $scope.settings.productCategoryIds ? $scope.settings.productCategoryIds.value : [],
            tradeClassCategoryIds: $scope.settings.tradeClassCategoryIds ? $scope.settings.tradeClassCategoryIds.value : [],
            pricePlanCategoryIds: $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : [],
            isFinance: $scope.settings.finance ? $scope.settings.finance : false
        };
    }

    $scope.filter = initFilter();

    $scope.filterChanged = function(filter) {
        $scope.filter = filter || initFilter();
        $scope.getVariance();
    };

    function addYearsToMonths() {
        var year = $scope.fiscalYear;
        if(moment().month(months[0]).month() > 0)
            year--;
        $scope.months = _.map(months, function(month, index) {
            var monthNumber = moment().month(month).month();
            if(monthNumber == 0 && index !== 0)
                year++;
            return {
                display: month, // + ' - ' + year,
                value: monthNumber
            };
        });
    }

    $scope.yearChange = function() {
        fiscalYearService
            .getFiscalYearMonthsOrdered()
            .then(function(months) {
                var startMonth = moment().month(months[0]).month();
                var currentMonth = moment().month();


                $scope.months = _(months)
                    .map(function(m) {
                        return {
                            value: moment().month(m).month(),
                            display: m
                        };
                    })
                    .filter(function(m) {
                        return fiscalYearOfToday > $scope.fiscalYear || m.value <= currentMonth || m.value >= startMonth;
                    })
                    .value();

                var lastMonth = $scope.months[$scope.months.length - 1].value;

                if(!$scope.month || (fiscalYearOfToday == $scope.fiscalYear && $scope.month > lastMonth && $scope.month < startMonth))
                    $scope.month = currentMonth;
            });
    };

    $scope.getVariance = function() {
        $scope.grid.setColDefs(getColDefs());

        var params = {
            fy: $scope.filter.fiscalYear,
            h: $scope.filter.divisionId,
            wdo: $scope.filter.workingDayOffset,
            pc: $scope.filter.productCategoryIds,
            tc: $scope.filter.tradeClassCategoryIds,
            ppc: $scope.filter.pricePlanCategoryIds
        };

        if(!$scope.filter.showEntireFiscalYear)
            params.m = $scope.filter.month;

        var rowPromise = fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()

            //api/subscribers/23443342432432/budgets/variance
            .get(params)
            .then(function(data) {
                $scope.lastTrxDate = data.lastTrxDate ? moment.utc(data.lastTrxDate).format('l') : '-';
                $scope.workingDays = data.workingDays || $scope.workingDays;

                if(data.dataThroughDate) {
                    if((moment(data.endDate).year() < moment().year()) || (moment(data.endDate).month() < moment().month())) {
                        $scope.dataThroughDate = moment.utc(data.endDate).format('l');
                        $scope.settings.disableClosedMonthOffset = true;
                        $scope.filter.workingDayOffset = '-';
                    } else {
                        $scope.dataThroughDate = moment.utc(data.dataThroughDate).format('l');

                        if(isNaN($scope.filter.workingDayOffset)) {
                            $scope.filter.workingDayOffset = 2;
                            $scope.getVariance();
                        }

                        $scope.settings.disableClosedMonthOffset = false;
                    }
                } else {
                    // $scope.dataThroughDate = '-';
                    $scope.filter.month = moment.utc().subtract(1, 'month').month();
                    $scope.getVariance();
                }

                $scope.startDate = data.startDate ? moment.utc(data.startDate).format('l') : '-';
                $scope.endDate = data.endDate ? moment.utc(data.endDate).format('l') : '-';

                return data.budgets;
            });
        var promise = $scope.grid.setRows(rowPromise);
        $scope.variancePromises = new PromiseMonitor(promise);

    };

    var gridOptions = {

        groupAggFunction: function(rows) {
            return _.reduce(rows, function(result, row) {
                var data = row.data;

                if(data) {
                    var keys = ['current', 'budgeted', 'projectedPeriodEnd', 'periodBudget'];
                    _.forEach(keys, function(key) {
                        result[key].units += data[key].units;
                        result[key].grossMargin += data[key].units * data[key].unitMargin;

                        if(data[key].units) {
                            if(result[key].grossMargin > 0) {
                                result[key].unitMargin = result[key].grossMargin / result[key].units;
                            }
                        }
                    });

                    result.variance = {
                        units: result.current.units - result.budgeted.units,
                        grossMargin: result.current.grossMargin - result.budgeted.grossMargin,
                        unitMargin: result.current.unitMargin - result.budgeted.unitMargin
                    };

                    result.percentVariance = {
                        units: result.budgeted.units ? (result.variance.units / result.budgeted.units) * 100 : 0,
                        grossMargin: result.budgeted.grossMargin ? (result.variance.grossMargin / result.budgeted.grossMargin) * 100 : 0,
                        unitMargin: result.budgeted.unitMargin ? (result.variance.unitMargin / result.budgeted.unitMargin) * 100 : 0
                    };

                    result.gallonsPerDayRequired = {
                        units: (result.periodBudget.units - result.current.units) / ($scope.workingDays.total - $scope.workingDays.elapsed) > 0
                        && isFinite((result.periodBudget.units - result.current.units) / ($scope.workingDays.total - $scope.workingDays.elapsed))
                        && !isNaN((result.periodBudget.units - result.current.units) / ($scope.workingDays.total - $scope.workingDays.elapsed))
                            ? (result.periodBudget.units - result.current.units) / ($scope.workingDays.total - $scope.workingDays.elapsed)
                            : 0
                    };

                    result.projectedVariance = {
                        units: result.projectedPeriodEnd.units - result.periodBudget.units
                    };

                    result.projectedPercentVariance = {
                        units: !isNaN((result.projectedPeriodEnd.units - result.periodBudget.units) / result.periodBudget.units)
                            ? ((result.projectedPeriodEnd.units - result.periodBudget.units) / result.periodBudget.units) * 100
                            : 0
                    };
                }

                return result;
            }, {
                current: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0,
                },
                budgeted: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0,
                },
                projectedPeriodEnd: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0,
                },
                periodBudget: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0,
                }
            });

        }
    };

    function getColDefs() {
        var nextIndex = $scope.nodeColDefs ? $scope.nodeColDefs.length : 0;
        var colDefs = $scope.nodeColDefs ? _.cloneDeep($scope.nodeColDefs) : [];


        colDefs.push(gridService2.colDef.createText('productCategory', 'Budget Products', 'productCategory', {
            rowGroupIndex: nextIndex,
            hide: true
        }));
        colDefs.push(gridService2.colDef.createText('tradeClassCategory', 'Budget Trade Classes', 'tradeClassCategory', {
            rowGroupIndex: nextIndex + 1,
            hide: true
        }));
        colDefs.push(gridService2.colDef.createText('pricePlanCategory', 'Budget Price Plans', 'pricePlanCategory', {
            rowGroupIndex: nextIndex + 2,
            hide: true
        }));

        var groups = ['Units'];

        if(!$scope.restrictedAccess.costAndMargin) {
            groups.push('Unit Margin');
            groups.push('Gross Margin');
        }

        var headers = ['Current', 'Budgeted', 'Variance', 'Percent Variance', 'Projected Period End', 'Period Budget'];

        return _.reduce(groups, function(result, group) {
            _.forEach(headers, function(header) {
                var headerDisplay = header + ' (' + group + ')';
                var field = header.camelize() + '.' + group.camelize();
                var colId = header.camelize() + '_' + group.camelize();

                var colOpts = {};

                if(header !== 'Variance' && header !== 'Percent Variance') {
                    colOpts.hide = true;
                }

                if(header === 'Percent Variance') {
                    headerDisplay = '% Variance' + ' (' + group + ')';
                    if(group === 'Units') {
                        headerDisplay = '% Variance' + ' (Gallons)';
                    } else if(group === 'Unit Margin') {
                        headerDisplay = '% Variance' + ' (Gallon Margin)';
                    }

                    if(header === 'Variance') {
                        if(group === 'Units') {
                            headerDisplay = 'Variance' + ' (Gallons)';
                        } else if(group === 'Unit Margin') {
                            headerDisplay = 'Variance' + ' (Gallon Margin)';
                        }
                    }

                    result.push(gridService2.colDef.createPercentage(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 0,
                        colorPositive: true
                    }));
                } else if(group === 'Units') {
                    headerDisplay = header + ' (Gallons)';

                    result.push(gridService2.colDef.createNumber(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 1,
                        colorPositive: (header === 'Variance')
                    }));
                } else if(group === 'Unit Margin' && header !== 'Projected Period End' && header !== 'Period Budget') {
                    headerDisplay = header + ' (Gallon Margin)';

                    result.push(gridService2.colDef.createCurrency(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 4,
                        colorPositive: (header === 'Variance')
                    }));
                } else if(group === 'Unit Margin') {
                    headerDisplay = header + ' (Gallon Margin)';

                    result.push(gridService2.colDef.createCurrency(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 4,
                        colorPositive: (header === 'Variance')
                    }));
                } else if(group === 'Gross Margin') {
                    result.push(gridService2.colDef.createCurrency(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 2,
                        colorPositive: (header === 'Variance')
                    }));
                } else {
                    result.push(gridService2.colDef.createNumber(colId, headerDisplay, field, colOpts, {
                        decimalPlaces: 4,
                        colorPositive: false
                    }));
                }
            });

            colDefs.push(gridService2.colDef.createNumber('gallonsPerDayRequired_units', 'Gallons / Day Required', 'gallonsPerDayRequired.units', {aggFunc: 'sum'}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('projectedVariance_units', 'Projected Variance (Gallons)', 'projectedVariance.units', { aggFunc: 'sum' }, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('projectedPercentVariance_units', 'Projected % Variance (Gallons)', 'projectedPercentVariance.units', {}, { decimalPlaces: 1 }));

            return result;
        }, colDefs);
    }

    function getYears() {
        return fluentRest
            .api()
            .subscribers($rootScope.user.subscriberId)
            .budgets()
            .years()
            .get()
            .then(function(years) {
                $scope.years = years;
            });
    }

    getYears();
    var months;
    $q.all([
        restrictedAccessService
            .getAccess(),
        nodeType
            .getDivisionColDefs(subscriberId),
        fiscalYearService
            .getFiscalYearMonthsOrdered()
    ])
        .spread(function(access, nodeColDefs, m) {
            $scope.restrictedAccess = access.restrictedAccess;
            $scope.nodeColDefs = nodeColDefs;

            months = m;
            $scope.years = _.range(moment().subtract(5, 'years').year(), moment().add(2, 'years').year(), 1);
            fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());
            addYearsToMonths();
            $scope.yearChange();

            var gridParams = {
                gridOptions: gridOptions,
                gridState: gridState(subscriberId, $scope.widgetCode),
                defs: getColDefs(),
                exportOptions: {fileName: 'Budget Variance'}
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid) {
            $scope.grid = grid;
            $scope.getVariance();
        });
}
