angular.module('angus.controllers').controller('budgetRunRateSubRegSettingsCtrl', [
    '$scope', '$rootScope', '$q', 'constantsService', 'budgetsService', 'hierarchyService', '_','modalService',
    function($scope, $rootScope, $q, constantsService, budgetsService, hierarchyService, _, modalService) {

        'use strict';

        $scope.periodIndicators = constantsService.budgetPeriodIndicators.enums;
        $scope.settings.toggleViewOptions = [{
            value: 'Percents',
            id: 1
        }, {
            value: 'Numbers',
            id: 2
        }];

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.settings.hierarchyNodeId = nodeId;
                });
        };

        var budgets = new budgetsService($scope.subscriberId);

        function selectedCategory(categories, categoryIds){
            _.forEach(categories, function(category){
                category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId }) ;
            });
        }

        $scope.loadSettings = function(){
            var params = { sortBy: 'name', ascending: 1 };
            $scope.finance ? params.onlyFinance = 1 : null;

            return   $q.all([
                budgets
                    .getProducts(params)
                    .then(function(productCategories){
                        selectedCategory(productCategories, $scope.settings.productCategoryIds ? $scope.settings.productCategoryIds.value : []);
                        $scope.productCategories = productCategories;
                    }),
                budgets
                    .getTradeClasses(params)
                    .then(function(tradeClassCategories){
                        selectedCategory(tradeClassCategories, $scope.settings.tradeClassCategoryIds ? $scope.settings.tradeClassCategoryIds.value : []);
                        $scope.tradeClassCategories = tradeClassCategories;
                    }),
                budgets
                    .getPricePlans(params)
                    .then(function(pricePlanCategories){
                        selectedCategory(pricePlanCategories, $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : []);
                        $scope.pricePlanCategories = pricePlanCategories;
                    })
            ]);
        };
        $scope.loadSettings()
    }
]);
