angular.module( 'angus.directives' ).directive( 'budgetRunRate', [
    'actionViewTemplates',
    function( actionViewTemplates ) {
        return {
            scope: {
                model      : "=actionModel",
                settings   : "=budgetRunRateWidgetSettings",
                widgetCode : "="
            },
            templateUrl: actionViewTemplates.budgetRunRateContainer
        };
    }
] );